<template>
  <div>
    <page-header
      :title="'Dashboard'"
      :sub-heading="true"
      :sub-header="'Dashboard'"
      :link-name="'Dashboard'"
      :link-url="'/'"
    >
    </page-header>
    <div class='con' >
    <div class="cards">
      <div class="card">
        <div class="head">Total Users</div>
        <div class="body">{{users.total}}</div>
        <div>
          <span>Last week: {{users.last_week}}</span>
          <span class="per">{{Math.floor((users.last_week/users.total)*100)}}%</span>
        </div>
      </div>

      <div class="card">
        <div class="head">Paid Active Subscriptions</div>
        <div class="body">{{paidSubscriptios.total}}</div>
        <div>
          <span>Last week: {{paidSubscriptios.last_week}}</span>
          <span class="per">{{Math.floor((paidSubscriptios.last_week/paidSubscriptios.total)*100)}}%</span>
        </div>
      </div>

      <div class="card">
        <div class="head">Free Active Subscriptions</div>
        <div class="body">{{freeSubscriptios.total}}</div>
        <div>
          <span>Last week: {{freeSubscriptios.last_week}}</span>
          <span class="per">{{Math.floor((freeSubscriptios.last_week/freeSubscriptios.total)*100)}}%</span>
        </div>
      </div>

      <div class="card">
        <div class="head">Total Revenue</div>
        <div class="body">&#163; {{revenue.total.toFixed(3)}}</div>
        <div>
          <span>Last week: &#163;{{revenue.last_week}}</span>
          <span class="per">{{Math.floor((revenue.last_week/revenue.total)*100)}}%</span>
        </div>
      </div>

    </div>
    <div class="cards">
      <div class="graph-card">
        <div class="header">
          <span class="title">
            Revenue
          </span>
          <span>
            <select name="" id="" @change="graph" v-model="period" >
              <option value="week">Weekly</option>
              <option value="month">Monthly</option>
              <option value="year">Yearly</option>
            </select>
          </span>
        </div>
        <div>
          Total Revenue: <span class="text-bold"> &#163; {{revenue.total.toFixed(3)}}</span>
        </div>
          <vue-apex-charts
            v-if="series[0].data.length > 0"
            :key="false"
            :options="options"
            :series="series"
            height="350"           
          ></vue-apex-charts>

      </div>

      <div class="sub-card">
        <div class="header">
          <span class="title">
            Subscriptions
          </span>
          <span>
            <select name="" id="" @change="graph()" v-model="period">
              <option value="week">Weekly</option>
              <option value="month">Monthly</option>
              <option value="year">Yearly</option>
            </select>
          </span>
        </div>
        <div class="sub">
          <div class="head">Total Users</div>
          <div class="body">{{users.total}}</div>
          <div>
            <span>Last week: {{users.last_week}}</span>
            <span class="per">{{Math.floor((users.last_week/users.total)*100)}}%</span>
          </div>
        </div>
        <div class="content">
          <div>
            <div class="text"> Users </div>
            <div class="bar">
              <span :style="`width: 100%; background-color:#124F4E`"></span>
            </div>
            {{users.total}}
          </div>

          <div>
            <div class="text"> Startup </div>
            <div class="bar">
              <span :style="`width:${(startups/users.total)*100}%; background-color:#1D817F`"></span>
            </div>
            <span>{{startups}}</span>
          </div>

          <div>
            <div class="text"> Growth </div>
            <div class="bar">
              <span :style="`width:${(paidSubscriptios.total/users.total)*100}%; background-color:#3DD598`"></span>
            </div>
            <span>{{paidSubscriptios.total}}</span>
          </div>
          <div>
            <div class="text"> Deleted </div>
            <div class="bar">
              <span :style="`width:${(deleted/users.total)*100}%; background-color:#E15555`"></span>
            </div>
            <span>{{deleted}}</span>
          </div>

        </div>

      </div>

    </div>
    <div class="cards">
      <div class="sub-card">
        <div class="header">
          <span class="title">
            Coupons
          </span>
          <router-link to="/coupons" class="link">
            View Coupons Page
          </router-link>
        </div>

        <dir>
          Active Coupon:   <span class="text-bold"> {{couponsRow.length}} </span>
        </dir>

        <table-view
          :colomns="couponsCol"
          :rows="couponsRow"
        >

        </table-view>

      </div>
      <div class="graph-card table">
           <div class="header">
          <span class="title">
            Users
          </span>
          <router-link to="/users" class="link">
            View Users Page
          </router-link>
        </div>
        <table-view
          :colomns="usersCol"
          :rows="usersRow"
        >

        </table-view>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import axios from "../../../axios";
// import subscriptios from '../../../db/subscriptios';/
import PageHeader from '../../layouts/components/fullPage/PageHeader.vue';
import VueApexCharts  from "vue-apexcharts";
// import coupons from "../../../db/coupons";
import tableView from './components/table.vue';
export default {
  components: { PageHeader, tableView, VueApexCharts },
  name: "Dashboard",
  data() {
    return {
      period: 'week',
      scales:
        [
          { icon: 'None', text:'None',  value:1 },
          { icon: '1 k',  text:'Thousands', value:1000 },
          { icon: '1 M',  text:'Milions',   value:1000000 },
          { icon: '1 B',  text:'Bilions',   value:1000000000 }
        ],
      series:
        [
          { name: "Revenue",     type:'bar',  data:[] },
      
      ],
        options:
        {
           fill: { opacity: 1 },
          plotOptions: { bar: { rangeBarGroupRows: false, columnWidth: '50%', distributed: false, } },
          colors: ["#3DD598", "#FFBC2C", "#1d817f"],
          chart: { height: 350, zoom: { enabled: false }, toolbar: { show: (this.setup && this.allow) ? true : false } },
          dataLabels: { enabled: false },
          stroke: { colors: ['#fff','#fff','#1d817f'], curve: ["stepline","stepline","straight"], width: [3,3,4] },
          markers: { colors: ['#CCCCCC'], strokeColors: '#1d817f', strokeWidth: 3, size: [3], hover: { sizeOffset: 2 } },
          legend: { itemMargin: { horizontal: 10, vertical: 15 } },
          grid: { padding: { top: 20, bottom: 0 }, row: { opacity: 0.75 } },
          xaxis: { categories: [], tickPlacement: 'between', position: "bottom", title: false, labels: { offsetY: 2 } },
          yaxis:
          [
            { seriesName: 'A', tickAmount: 5, axisTicks: { show: true }, axisBorder: { show: true, }, labels:{} },
            { seriesName: 'A', tickAmount: 5, show: false, labels:{} },
            { seriesName: 'B', tickAmount: 5, opposite: true, labels:{} }
          ],
          responsive:
          [
            { breakpoint: 768,  options: { legend: { position: 'top', horizontalAlign: 'left', itemMargin: { vertical: 10 } }, grid: { padding: { top:20, bottom: 20 } } } },
            { breakpoint: 4096, options: { legend: { position: 'top', horizontalAlign: 'right', itemMargin: { vertical: 0 } }, grid: { padding: { top:20, bottom: 0 } } } }
          ]
        },
      users: {
        total: 0,
        last_week: 0,
        last_month: 0,
      },
      freeSubscriptios: {
        total: 0,
        last_week: 0,
        last_month: 0,
      },
      revenue: {
        total: 0,
        last_week: 0,
        last_month: 0,
      },
    
      paidSubscriptios: {
        total: 0,
        last_week: 0,
        last_month: 0,
      },
      startups: 0,
      growth: 0,
      deleted: 0,
      couponsRow: [],
      usersRow: [],
      couponsCol: [
        {
          name: "Coupon Code",
          field: "id",
        },
        {
          name: "Discount Price (%)",
          field: "percent_off",
        },
        {
          name: "User count",
          field: "times_redeemed",
        },
      ],
      usersCol:[
        {
          name: "Name",
          field: "name",
        },
        {
          name: "Email",
          field: "email",
        },
        {
          name: "Role",
          field: "role",
        },
        {
          name: "Subscription",
          field: "subscription",
        },
        {
          name: "last login",
          field: "last_login",
        }
      ],

    };
  },
  methods: {
    async usersData(){
      // const loading = this.$vs.loading();
      axios.defaults.headers.common["Authorization"] = this.$store.state.accessToken;
      axios.get(`${this.$store.state.server.requestUri}/admin/users`)
      .then(res => {
        // console.log(res.data);
        this.users.total = res.data.data.length;
        let last_week = res.data.data.filter(user => {
          var date = new Date();
          var lastWeek = new Date(date.setDate(date.getDate() - date.getDay()));
          // console.log(lastWeek);
          return new Date(user.created_at).getTime() >= lastWeek.getTime() - (7 * 24 * 60 * 60 * 1000) && new Date(user.created_at).getTime() < lastWeek.getTime();
        })

        this.users.last_week = last_week.length

        console.log(last_week);
        console.log(this.users.last_week);
        this.users.last_month = res.data.data.filter(user => {
          return new Date(user.created_at).getTime() > new Date().getTime() - 30 * 24 * 60 * 60 * 1000;
        }).length;

        this.startups = res.data.data.filter(user => user.role === 'Startup').length;
        this.usersRow = res.data.data.map(user => {
          // console.log(user.created_at);
          return {
            name: user.name,
            email: user.email,
            role: user.role,
            subscription: user.subscripton !== null ? user.subscripton.name : 'Free',
            last_login: user.last_login
          }
        }).slice(0,10);
        // console.log(res.data.data.filter(user => user.role === 'Startup').length);
        // axios.defaults.headers.common["Authorization"] = this.$store.state.accessToken;
        axios.get(`${this.$store.state.server.requestUri}/admin/users/deactivated`)
        .then(res => {
          console.log(res.data);
          this.deleted =res.data.data.length;
        })


      //free subscriptios
      axios.get(`${this.$store.state.server.requestUri}/admin/subscriptions/Free`)
      .then(res => {
        console.log(res.data);
        this.freeSubscriptios.total = res.data.data.length;
        this.freeSubscriptios.last_week = res.data.data.filter(sub => {
          var date = new Date();
          var lastWeek = new Date(date.setDate(date.getDate() - date.getDay()));
          // console.log(lastWeek);
          return new Date(sub.created_at).getTime() >= lastWeek.getTime() - (7 * 24 * 60 * 60 * 1000) && new Date(sub.created_at).getTime() < lastWeek.getTime();
        }).length;
      })

      //paid subscriptios

       axios.get(`${this.$store.state.server.requestUri}/admin/subscriptions/Paid`)
      .then(res => {
        console.log(res.data);
        this.paidSubscriptios.total = res.data.data.length;
        this.paidSubscriptios.last_week = res.data.data.filter(sub => {
          var date = new Date();
          var lastWeek = new Date(date.setDate(date.getDate() - date.getDay()));
          // console.log(lastWeek);
          return new Date(sub.created_at).getTime() >= lastWeek.getTime() - (7 * 24 * 60 * 60 * 1000) && new Date(sub.created_at).getTime() < lastWeek.getTime();
        }).length;
      })

      // revenue year

      axios.get(`${this.$store.state.server.requestUri}/admin/subscription-revenue/year`)
      .then(res => {
        // console.log(res.data);
        this.revenue.total = res.data.data.total;
      })
      // revenue month
      axios.get(`${this.$store.state.server.requestUri}/admin/subscription-revenue/week`)
      .then(res => {
        // console.log(res.data);
        this.revenue.last_week = res.data.data.total;
      })

        // loading.close();
      })
    },
    getRevenueTotal(){
      axios.defaults.headers.common["Authorization"] = this.$store.state.accessToken;
      

    },

    async graph(){
      this.options.xaxis.categories=[];
      this.series[0].data=[];
      axios.defaults.headers.common["Authorization"] = this.$store.state.accessToken;
      const loading = this.$vs.loading();
      axios.get(`${this.$store.state.server.requestUri}/admin/subscription-revenue/${this.period}`)
      .then(res => {
    
        // this.revenue.last_month = res.data.data.total;
        const months = {
          '1': 'Jan',
          '2': 'Feb',
          '3': 'Mar',
          '4': 'Apr',
          '5': 'May',
          '6': 'Jun',
          '7': 'Jul',
          '8': 'Aug',
          '9': 'Sep',
          '10': 'Oct',
          '11': 'Nov',
          '12': 'Dec'
        }

        for (const key in res.data.data.revenue) {
          if (this.period == 'month'){
            this.options.xaxis.categories.push(months[key]);
          }else{
            this.options.xaxis.categories.push(key);
          }
         this.series[0].data.push(res.data.data.revenue[key]);
        //  this.options.xaxis.categories.push(months[key]);
        }
        loading.close();
        // this.series[0].data = res.data.data.revenue;
  
        
      })
    },

    
    freeSub(){
      axios.defaults.headers.common["Authorization"] = this.$store.state.accessToken;
    
    },
    paidSub(){
      axios.defaults.headers.common["Authorization"] = this.$store.state.accessToken;
     
    },
    coupons(){
      axios.defaults.headers.common["Authorization"] = this.$store.state.accessToken;
      axios.get(`${this.$store.state.server.requestUri}/admin/coupons`)
      .then(res => {
        // console.log(res.data.data.data);
        this.couponsRow = res.data.data.data;
      })
    },
    
  },
  async created() {
    this.graph();
    this.usersData();
    this.coupons();

     
    
    // this.couponsRow = coupons;
  }
};
</script>

<style scoped>
  body{
    background: #FAFAFA;
  }
  .con{
    width: 100%;
  }
  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px;
    gap: 20px;
    max-width: 2000px;
    margin-top: 40px;
    width: 100%;
    padding: 0 0px;
  }

  .card{
    width: 100%;
    max-width: 450px;
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    padding: 20px;
  }
  .card div{
    font-size: 20px;
    margin-bottom: 10px;
    display: flex;
  }
  span{
    margin-right: 20px;
  }
  .per{
    margin-right: 20px;
    color: #999999;
  }
  .card .body{
    font-size: 30px;
    font-weight: bold;
  }
  .head{
    color: #646363;
  }

   .graph-card{
    width:100%;
    grid-column: 1/3;
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    height: auto;
    padding: 20px;
    overflow: auto;
  }
  .sub-card{
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    padding: 20px;
    overflow: auto;
  }
  .sub-card div, .graph-card div {
    /* text-align: center; */
    /* font-size: 15px; */
    margin-bottom: 10px;
    width: 100%;
  }
  .sub-card div .body{
    width: 100%;
    font-size: 30px;
    font-weight: bold;
  }
  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title{
    font-size: 20px;
    font-weight: bold;
  }
  select{
    height: 50px;
    border-radius: 4px;
    border: 1px solid #E5E5E5;
    padding: 5px;
    background: var(--unnamed-color-1d817f) 0% 0% no-repeat padding-box;
  }
  .link{
    color: #1d817f;
    text-decoration: none;
  }

  .content div{
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 20px;
    margin-top: 20px;
    padding: 0 20px;
    text-align: left;
    /* border: black 1px solid; */
  }
  
  
  .bar{
    width: 300px;
    text-align: left;
  }
  .bar span{
    /* background-color: #1d817f; */
    height: 10px;
    /* width: 100%; */
  }
  .content div .text{
    width: 100px;
    text-align: left;
    font-size: 20px;
    margin-right: 20px;
  }
  .text-bold{
    font-size: 25px;
    font-weight: bold;
    padding-left: 6px;
  }

  .sub{
    text-align: center;
  }
  .table{
    grid-column: 2/4;
  }

</style>
