<template>
    <div>
        <table>
            <thead>
                <tr>
                   <th v-for="(column, index) in colomns" :key="index">
                          {{column.name}}
                   </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, index) in rows" :key="index">
                    <td v-for="(column, index) in colomns" :key="index">
                        {{row[column.field]}}
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
</template>
<script>
export default {
    props: {
        colomns: {
            type: Array,
            required: true
        },
        rows: {
            type: Array,
            required: true
        }
    }
}
</script>
<style scoped>
    table {
        width: 100%;
        padding: 20px 5px; 
        
    }
    th{
        text-align: left;
        padding: 10px 0px;
        margin: 0px;
    }
    td{
        padding: 10px 0px;
        margin: 0px;
    }
    tr{
        margin: 0px;
    }



</style>